.swiper {
    padding-bottom: 15px;
}

.swiper-button-next,
.swiper-button-prev {
    color: rgba(255, 255, 255, 0.95);
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 100%;
    height: 32px;
    width: 24px;
}

.swiper-button-next {
    right: 0;
    border-radius: 10px 0 0 10px;
}

.swiper-button-prev {
    left: 0;
    border-radius: 0 10px 10px 0;
}

.swiper-button-next::after,
.swiper-button-prev::after {
    font-size: 12px;
}
